import { CheckIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import Header from './Header';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
];

const communicationFeatures = [
  {
    id: 1,
    name: 'Modern JavaScript & TypeScript',
    description: "Write your Edge Function with the languages and features you're familiar with.",
    icon: CheckIcon,
  },
  {
    id: 2,
    name: 'Latest Code Editor',
    description: "Our Edge IDE is based on VSCode, so you won't skip a beat.",
    icon: CheckIcon,
  },
  {
    id: 3,
    name: 'Typed and Formatted',
    description: 'All Edge Functions are typed and formatted with Prettier to ensure consistency and readability.',
    icon: CheckIcon,
  },
];
const transferFeatures = [
  {
    id: 1,
    name: 'Auto API Key Management',
    description:
      'Configure key creation to automatically provision proper credentials for every tool, user and environment without manual intervention.',
    icon: CheckIcon,
  },
  {
    id: 2,
    name: 'Security is top of mind',
    description: 'Use our whitelabel integrations to receive automatic patching, updates and monitoring.',
    icon: CheckIcon,
  },
  {
    id: 3,
    name: 'Zero Config',
    description:
      'All setup and configuration is automated and handled by us and is global. Log into the EdgeIDE from anywhere and pick up where you left off.',
    icon: CheckIcon,
  },
];
const features = [
  {
    name: 'Databases',
    description: 'Connect to your existing Postgres, MySQL, Redis, Clickhouse or Redshift databases.',
    icon: CheckIcon,
  },
  {
    name: 'Scale Instantly',
    description: 'Edge Functions with zero cold start scale as request count increases automatically.',
    icon: CheckIcon,
  },
  {
    name: 'Monitoring',
    description: 'Built in observability enables detailed monitoring and alerting.',
    icon: CheckIcon,
  },
  {
    name: 'Edge IDE',
    description: 'Our web based IDE is purpose built for writing Edge Functions quickly.',
    icon: CheckIcon,
  },
  {
    name: 'API Integrations',
    description: 'Simply add your API keys for Segment, Twilio, AWS, OpenAI etc. to get started.',
    icon: CheckIcon,
  },
  {
    name: 'No CI Pipeline',
    description: "Simply save your changes and they're live on your unique dev URL. ",
    icon: CheckIcon,
  },
  {
    name: 'Instant Deployments',
    description: "Bundle changes into a 'deployment' then go to production in a single click.",
    icon: CheckIcon,
  },
  {
    name: 'Automated Testing',
    description: 'Build relevant tests with automated mock data creation which run on each save. ',
    icon: CheckIcon,
  },
];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="isolate bg-white">
      {/* <Header /> */}
      <div className="hidden sticky top-0 z-50 px-6 py-3 shadow-sm bg-white lg:px-8">
        {/* backdrop-blur-2xl */}
        <nav className=" flex items-center justify-between" aria-label="Global">
          <div className="flex lg:flex-1 h-8">
            <a href="#" className="">
              {/* <span className="sr-only">EdgeOS</span>
              <img className="h-8" src="https://tailwindui.com/img/logos/mark.svg?color=black&shade=600" alt="" /> */}
            </a>
          </div>
          <div className="hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {/* {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))} */}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
            <div className="flex items-center justify-between">
              {/* <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">EdgeOS</span>
                <img className="h-8" src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600" alt="" />
              </a> */}
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {/* {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                    >
                      {item.name}
                    </a>
                  ))} */}
                </div>
                <div className="py-6"></div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
      <main>
        <div className="relative pt-4 pb-8 sm:pt-8 sm:pb-16">
          <div className="mx-auto max-w-6xl px-6 lg:px-10">
            <div className="mx-auto px-0">
              <h1 className="text-5xl font-bold leading-[1.3] tracking-tight text-gray-900 sm:text-7xl sm:leading-[1.2]">
                <span className="">EdgeOS</span> is{' '}
                <span className="font-extrabold italic bg-black text-white px-4 rounded-md">the</span> platform for{' '}
                <span className="bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 bg-bottom bg-no-repeat bg-[length:100%_6px]">
                  edge
                </span>{' '}
                development.
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-10 grid-flow-row mt-6 md:mt-12 gap-6">
                <h2 className="col-span-1 md:col-span-5 text-xl leading-7 font-normal text-gray-600 ">
                  A single, end-to-end platform for building, deploying, and scaling edge applications.
                </h2>
                <h2 className="hidden col-span-1 md:col-span-5 flex">
                  <div className="flex h-auto items-center justify-center md:justify-start bg-transparent md:ml-7 w-full md:w-auto">
                    <div className="group relative w-full md:w-auto ">
                      <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-gray-300  to-slate-300 opacity-50 blur transition duration-75 group-hover:opacity-100"></div>
                      <button className="w-full md:w-auto relative rounded-lg bg-gray-800 hover:bg-white hover:text-gray-900 transition-colors duration-75 px-5 py-3 text-white text-base border font-medium">
                        <div className="">Learn more</div>
                      </button>
                    </div>
                  </div>
                  <div className="flex h-auto items-center justify-center md:justify-start bg-transparent ml-5 w-full md:w-auto">
                    <div className="group relative w-full md:w-auto ">
                      <div className="absolute -inset-1 rounded-lg bg-gradient-to-tr from-rose-400 via-fuchsia-500 to-indigo-500 opacity-50 blur transition duration-75 group-hover:opacity-100"></div>
                      <button className="w-full md:w-auto relative rounded-lg bg-white px-5 py-3 text-gray-700 text-base font-medium">
                        <div className="bg-clip-text text-transparent bg-gradient-to-r from-gray-600 to-gray-600">
                          Get started
                        </div>
                      </button>
                    </div>
                  </div>
                </h2>
              </div>
            </div>
            <div className="mt-12 rounded-b-3xl bg-white min-h-min max-h-[790px] shadow-xl shadow-gray-200 overflow-hidden">
              <img src="app_view.png" alt="EdgeOS Application Screenshot" width={2140} height={1560} className="" />
            </div>
          </div>
          <div className="mx-auto max-w-6xl px-6 lg:px-10 mt-3 grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="col-span-2 md:col-span-1 rounded-3xl bg-gradient-to-br from-amber-300 to-pink-500 min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <h2 className="text-6xl lg:text-7xl text-white font-bold tracking-tight">The databases you need.</h2>
              <h3 className="text-2xl text-white font-semibold tracking-tight mt-8 ">
                Postgres, MySQL, Redis, Redshift, Clickhouse, Kafka and more.
              </h3>
            </div>
            <div className="col-span-2 md:col-span-1 rounded-3xl bg-white min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <div className="text-6xl lg:text-7xl text-gray-800 font-bold tracking-tight">No CI.</div>
              <div className="text-6xl lg:text-7xl text-gray-800 font-bold tracking-tight">No builds.</div>
              <div className="text-6xl lg:text-7xl text-green-500 font-bold tracking-tight">Write.</div>
              <div className="text-6xl lg:text-7xl text-green-500 font-bold tracking-tight">Deploy.</div>
            </div>
            <div className="col-span-2 rounded-3xl bg-white min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <div className="">
                <div className="mx-auto max-w-7xl px-2 lg:px-8">
                  <div className="mx-auto max-w-4xl text-center">
                    <h2 className="mt-2 text-5xl font-bold text-left tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 sm:text-7xl">
                      All-in-one platform.
                    </h2>
                    <h3 className="mt-6 mx-auto text-xl text-left  leading-8 text-gray-600 rounded-3xl p-0  max-w-4xl">
                      With our Edge IDE, you avoid setting up local machines, fidgeting with git and build pipelines.
                      When the IDE and runtime are integrated, the developer experience is seamless.
                    </h3>
                  </div>
                  <div className="mx-auto mt-16 mb-8 max-w-2xl lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-12">
                      {features.map((feature) => (
                        <div key={feature.name} className="relative pl-16">
                          <dt className="text-xl font-bold leading-7 text-gray-800">
                            <div className="absolute  left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg border border-gray-700  ">
                              <feature.icon className="h-6 w-6 text-gray-700 " aria-hidden="true" />
                            </div>
                            {feature.name}
                          </dt>
                          <dd className="mt-2 text-base font-normal leading-7 text-gray-800 pr-2">
                            {feature.description}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 rounded-3xl bg-sky-500 min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <h2 className="text-6xl lg:text-7xl text-white font-bold tracking-tight">APIs.</h2>
              <div className="text-3xl text-white font-semibold tracking-tight mt-8 ">
                Just add your API keys and you're ready to go.
              </div>
              <div className="text-2xl text-white font-semibold tracking-tight mt-8 ">
                Stripe, Twilio, Mailchimp, OpenAI and more.
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 rounded-3xl bg-gradient-to-br from-green-400 to-teal-600 min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <h2 className="text-6xl lg:text-7xl text-white font-bold tracking-tight">Monitoring.</h2>
              <div className="text-3xl text-white font-semibold tracking-tight mt-8 ">
                We monitor and scale your app for you.
              </div>
              <div className="text-2xl text-white font-semibold tracking-tight mt-8 ">
                AI powered auto-scaling, monitoring, and alerting.
              </div>
            </div>
            <div className="col-span-2 rounded-3xl bg-white min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <div className="text-6xl md:text-7xl text-gray-900 font-bold tracking-tight">
                Powered by{' '}
                <span className="font-bold bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 text-transparent bg-clip-text">
                  AI
                </span>
              </div>
              <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 mt-16 gap-12">
                <div className="text-3xl text-gray-900 font-semibold tracking-tight rounded-3xl bg-gray-100 p-5">
                  <span className="font-bold bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 text-transparent bg-clip-text">
                    Self Healing code.
                  </span>{' '}
                  Detect errors and apply fixes automatically.
                </div>
                <div className="text-3xl text-gray-900 font-semibold tracking-tight rounded-3xl bg-gray-100 p-5">
                  <span className="font-bold bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 text-transparent bg-clip-text">
                    Dynamic code generation
                  </span>{' '}
                  based on your connected data sources.
                </div>
                <div className="text-3xl text-gray-900 font-semibold tracking-tight rounded-3xl bg-gray-100 p-5">
                  <span className="font-bold bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 text-transparent bg-clip-text">
                    Optimize
                  </span>{' '}
                  data fetching logic and SQL queries inline as you write them.
                </div>
              </div>
            </div>
            <div className="col-span-2 rounded-3xl bg-slate-900 min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden">
              <div className="">
                <div className="mx-auto max-w-7xl px-2 lg:px-8">
                  <div className="mx-auto max-w-4xl  ">
                    <h2 className="mt-2 text-5xl font-bold tracking-tight text-white sm:text-6xl text-left">
                      A development environment built for Edge
                    </h2>
                    <h3 className="mt-12 mx-auto text-xl leading-8 text-white  rounded-3xl p-0 w-full">
                      Table stakes shouldn't be so tedious and error prone. Local developer machines usally need
                      constant care and attention. Running local servers, connecting to test databases and setting up
                      test credentials for Twilio... it all just got easier.
                    </h3>
                  </div>
                  <div className="mx-auto mt-16 sm:mb-8 max-w-2xl sm:mt-20 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 sm:gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                      <div>
                        {transferFeatures.map((feature) => (
                          <div key={feature.name} className="relative pl-16 mb-8">
                            <dt className="text-lg font-bold leading-7 text-white">
                              <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-transparent border border-white">
                                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                              </div>
                              {feature.name}
                            </dt>
                            <dd className="mt-2 text-base font-base leading-7 text-white">{feature.description}</dd>
                          </div>
                        ))}
                      </div>
                      <div>
                        <img
                          className="hidden sm:block relative mx-auto border-gray-50 border-8 rounded-lg"
                          width={490}
                          src="/feature-example-1.png"
                          alt=""
                        />
                      </div>
                    </dl>
                  </div>
                  <div className="mx-auto mt-0 sm:mt-16 mb-8 max-w-2xl lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 sm:gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                      <div>
                        <img
                          className="hidden sm:block relative mx-auto border-gray-50 border-8 rounded-lg"
                          width={490}
                          src="/type_screen.png"
                          alt=""
                        />
                      </div>
                      <div>
                        {communicationFeatures.map((feature) => (
                          <div key={feature.name} className="relative pl-16 mb-8">
                            <dt className="text-lg font-bold leading-7 text-white">
                              <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-transparent border border-white">
                                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                              </div>
                              {feature.name}
                            </dt>
                            <dd className="mt-2 text-base font-base leading-7 text-white">{feature.description}</dd>
                          </div>
                        ))}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden col-span-2 rounded-3xl bg-gradient-to-b from-gray-100 to-gray-400 min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden"></div>
            <div className="hidden col-span-2 rounded-3xl from-rose-400 via-fuchsia-500 to-indigo-500 bg-gradient-to-tr min-h-min shadow-xl shadow-gray-200 p-8 lg:p-10 overflow-hidden"></div>
          </div>
          <div className="mx-auto max-w-6xl px-6 lg:px-10 mt-24 grid grid-cols-1 md:grid-cols-2">
            <div className="col-span-2 rounded-3xl bg-white h-auto text-center py-8 px-10 text-gray-500">
              © 2023 EdgeOS. All rights reserved.
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
