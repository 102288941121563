import Home from './features/home/EdgeHero';
import { Routes, Route } from 'react-router-dom';

export default function App() {
  return (
    <div className="min-h-full">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
}
